import "../Girl.css";

function Intro() {
  return (
    <div className="girlAndText App-header" id="home">
      <header>
        <h3 className="introTop"><i>Behind every successful block of code, <br/> there's a woman...</i></h3>
      </header>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="250"
        height="350"
        className="girl"
      >
        <path
          d="M160.99 121.06l-44.9.36q-12.34 2.62-11.67 4.64c-.72 2.66 2.6 14.34 5.17 15.53q1.76 2.63 8.16 8.33c11.47 6.24 22.1 5.82 31.84-1.3q2.57-1.88 4.63-4.37c2.52-3.05 3.6-4.72 4.84-7.66q1.53-3.65 2.19-10.97l-.26-4.56z"
          fill="#F2E0D7"
        />
        <path
          class="move-up-small"
          d="M111.36 107.32c-.69.9-5.33 7.98-6.2 13.05-.76 4.34.35 12 .76 12.41.13.14 15.19-16.17 14.58-14.66-.15.36-1.93 4.38-2.57 6.33-1.57 4.8-1.1 5.24.97 4.24 4-2.48 14.34-4.72 18.03-4.16 2.76-.2 8.77.72 12.38 2.08 1.44.13 4.4.51 5.6.32 1.88-.3 3.18-1.63 3.85-1.92 3.53-5.21 0-14.34-8.57-21.48-1.99-1.45-6.68-4.07-7.28-4.07-.5.05-23.28 1.38-23.32 1.38-.03 0-6.24 3.89-8.23 6.48z"
          fill="#FD6E21"
        />
        <path
          class="shadow"
          d="M141.95 100.56c-.43.9-2.7 4.64-2.31 4.53 3.3.31 8.6-2.91 7.3-4.06-3.48-1.75-4.11-2.32-5-.47z"
          fill="#CB591A"
        />
        <path
          class="move-up"
          d="M120.22 83.07l-4.68 2.04.36 6.18 6.26.28 2.18-5.93-4.12-2.57z"
          fill="#3dcb8d"
        />
        <path
          class="move-up"
          d="M127.39 80.64q-.8 1.2-1.86 2.45-.51-.66-.93-.94c-.94-.61-5.42-.54-7.6-.43-2.78.08-3.34 2.34-3.23 5.65.12 3.32-.45 6.58 3.79 6.59h2.22q-.12 1.63-.19 2.9l.03 3.07 23.53-1q-.42-1.84-.8-3.86c-.94-4.87-5.17-8.48-10.6-9.03-2.35-.24-2.57-.68-1.7-3.29q.41-1.2 1-2.78l-2.45-1.34q-.8 1.42-1.21 2.01zm-4.91 4.15c.9.63.86.85-.64 3.76l-1.31 2.52-1.64.1c-2.34.12-2.82-.47-2.7-3.35.15-3.18.44-3.5 3.3-3.5 1.62 0 2.52.14 2.99.47zm-4.14 1.95v2.18l2.21-.02v-2.18l-2.21.02zm8.86-1.23q.03 1.74.64 2.41c.57.63 1.27.87 2.14.9 6.92.03 11.68 6.53 8.91 12.16-.77 1.56-1.4 1.64-2.56.32a4.21 4.21 0 0 0-7.48 2.82c0 2.59-4.88-1-5.86-4.31-.97-3.27-.28-6.6 2.31-11.21q.58-1.02 1.9-3.09zm15.95 13.41l-23.53 1.01q.34 1.57 1.44 3.2c1.79 2.66 3.61 4.04 6.78 5.14l4.45.82q-.15 1.56.25 2.27c.4.71 1.11.91 1.11 1.76 0 4 2.65 4.55 4.16 4.37.69-.08 1.82-.83 2.51-.77 2.55.26 4.27-.93 3.45-3.6-.25-.84-1.27-2.05-2.5-3.72-1.23-1.67-1.78-2.13-1.86-2.67-.18-.24.69-1.67 1.07-2.22.33-.46.95-1.54 1.39-2.4q.95-1.9 1.28-3.19z"
          fill="#272727"
        />
        <path
          class="move-up"
          d="M133.03 103.25q-.22.14-.32.63L136 108l-1.55 1.2q-.15.1-.16.2-.01.1.13.24l.68.86q.1.12.18.14.1.02.25-.1l1.42-1.08 1.21 1.57-3.06 2.39q-.1.08-.12.15 0 .06.08.16l1.58 2.04q.09.1.16.1.07.02.2-.07l2.98-2.28 1.2 1.41q.52.08.74-.08.24-.18.28-.76l-8.53-10.88q-.44-.09-.64.04z"
          fill="#3dcb8d"
        />
        <g class="eyes" fill="#272727">
          <path d="M123.23 141.93a2.33 2.33 0 1 1 4.66 0 2.33 2.33 0 0 1-4.66 0zM140.2 141.96a2.33 2.33 0 1 1 4.66 0 2.33 2.33 0 0 1-4.67 0z" />
        </g>
        <path
          class="move-up"
          d="M105.88 135.68l11.81-12.42-1.32 6.8 4.14-1.74q7.43-3.26 11.78-3.63 3.57-.32 10.11.88 4.41 1.84-1.94 1.78c-6.35-.07-10.27.51-15.39 2.62q-3.53 1.45-9.25 5.6l1.23-3.95-2.06-1.4-8.02 8.87-1.09-3.4z"
          fill="#C3C3C3"
          fill-opacity=".6"
        />
        <path
          class="move-up"
          d="M104.66 133.75l17.28-18.1-4.22 13.02q7.32-3.25 11.57-3.98c3.78-.66 7.07-.66 10.88-.31q3.49.32 9.74 2 4.46.7 6.44-.27 3.78-1.84 3.5-7.24l1 6.86q-2.8 3.3-5.56 3.82c-4.54.84-11.48-2.14-17.83-2.2-6.35-.07-10.27.51-15.39 2.62q-3.53 1.45-9.25 5.6l2.98-9.58-10.06 11.17-1.08-3.4z"
          fill="#272727"
        />
        <path
          d="M104.31 125.59c0-15.92 12.8-28.84 28.59-28.84 15.77 0 28.58 12.92 28.58 28.84 0 15.91-12.8 28.84-28.58 28.84-15.78 0-28.59-12.93-28.59-28.84z"
          fill="none"
          stroke-width="2"
          stroke="#272727"
          stroke-linecap="square"
          stroke-miterlimit="3"
        />
        <path
          class="move-up"
          d="M127.2 85.51q.03 1.74.64 2.41c.57.63 1.27.87 2.14.9 6.92.03 11.68 6.53 8.91 12.16-.77 1.56-1.4 1.64-2.56.32a4.21 4.21 0 0 0-7.48 2.82c0 2.59-4.88-1-5.86-4.31-.97-3.27-.28-6.6 2.31-11.21q.58-1.02 1.9-3.09z"
          fill="#FD6E21"
        />
        <path
          d="M82.6 148.77h101.08a4.42 4.42 0 0 1 4.42 4.42v61.47a4.42 4.42 0 0 1-4.42 4.43H82.59a4.42 4.42 0 0 1-4.42-4.43V153.2a4.42 4.42 0 0 1 4.42-4.42z"
          fill="#808080"
        />
        <path
          d="M82.6 148.77h101.08a4.42 4.42 0 0 1 4.42 4.42v61.47a4.42 4.42 0 0 1-4.42 4.43H82.59a4.42 4.42 0 0 1-4.42-4.43V153.2a4.42 4.42 0 0 1 4.42-4.42z"
          fill="none"
          stroke="#272727"
          stroke-width="2"
          stroke-linecap="square"
          stroke-miterlimit="3"
        />
        <path
          d="M124.36 183.93a8.66 8.66 0 1 1 17.31 0 8.66 8.66 0 0 1-17.31 0z"
          fill="#FFF"
        />
      </svg>
      <header>
        <h1 className="name">HI, I'M MANALI BISWAS</h1>
        <a className="App-link" href="#work">
          <i class="bi bi-arrow-down-circle-fill"></i> Scroll Down
        </a>
      </header>
    </div>
  );
}

export default Intro;
